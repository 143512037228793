import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/components/Hero.css";
import SearchBar from "./SearchBar"; // Neue SearchBar-Komponente

const Hero = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("token"); // Prüft, ob der User eingeloggt ist

  const handleReviewClick = () => {
    if (isAuthenticated) {
      navigate("/newreview");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Finde die besten lokalen Geschäfte in deiner Nähe</h1>
        
        {/* Neue SearchBar-Komponente */}
        <SearchBar />

        <div className="cta-buttons">
          <button onClick={handleReviewClick}>Jetzt bewerten</button>
          <button onClick={() => navigate("/categories")}>Geschäfte durchsuchen</button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
