// src/components/Categories.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/components/Categories.css";

// Kategorien-Daten
const categories = [
  { id: "restaurants", name: "Restaurants", icon: "🥘", description: "Finde die besten Restaurants." },
  { id: "friseure", name: "Friseure", icon: "💇", description: "Styling und Pflege." },
  { id: "shops", name: "Shops", icon: "🛍️", description: "Entdecke lokale Geschäfte." },
  { id: "dienstleistungen", name: "Dienstleistungen", icon: "🛠️", description: "Hilfe und Services." },
  { id: "fitness", name: "Fitnessstudios", icon: "🏋️", description: "Gesundheit und Fitness." },
  { id: "kinos", name: "Kinos", icon: "🎥", description: "Erlebe die neuesten Filme." },
];

const Categories = ({ limit }) => {
  const navigate = useNavigate();
  
  // Begrenze die Anzahl der Kategorien, falls `limit` gesetzt ist
  const categoriesToShow = limit ? categories.slice(0, limit) : categories;

  return (
    <div className="categories">
      <h2>{limit ? "Beliebte Kategorien" : "Alle Kategorien"}</h2>
      <div className="categories-grid">
        {categoriesToShow.map((category) => (
          <div key={category.id} className="category-card" onClick={() => navigate(`/categories/${category.id}`)}>
            <span className="category-icon">{category.icon}</span>
            <h3>{category.name}</h3>
            <p>{category.description}</p>
          </div>
        ))}
      </div>
      {limit && (
        <div className="categories-button">
          <button onClick={() => navigate("/categories")}>Alle Kategorien anzeigen</button>
        </div>
      )}
    </div>
  );
};

export default Categories;
