import React, { useEffect, useState } from "react";
import { getTopRatedCompanies } from "../api";
import "../styles/components/TopRated.css";

const TopRated = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchTopRated = async () => {
      try {
        const data = await getTopRatedCompanies();
        setCompanies(data);
      } catch (error) {
        console.error("Fehler beim Laden der besten Unternehmen:", error);
      }
    };
    fetchTopRated();
  }, []);

  return (
    <div className="top-rated">
      <h2>Top-Bewertungen</h2>
      <div className="top-rated-list">
        {companies.map((company, index) => (
          <div key={index} className="top-rated-card">
            <h3>{company.name}</h3>
            <p>⭐ {company.rating.toFixed(1)}</p>
            <p>"{company.comment || 'Keine Kommentare'}"</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopRated;
