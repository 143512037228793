import React, { useEffect, useState } from "react";
import { getAllCompanies } from "../api/company"; // Korrekte API-Struktur
import Hero from "../components/Hero";
import Categories from "../components/Categories";
import TopRated from "../components/TopRated";
import Map from "../components/Map";
import NewestReviews from "../components/NewestReviews";
import "../styles/pages/Home.css"; // Falls spezifisches Styling benötigt wird

const Home = () => {
  const [companies, setCompanies] = useState([]);
  const [searchResults, setSearchResults] = useState([]); // Suchergebnisse

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const data = await getAllCompanies();
        setCompanies(data);
      } catch (error) {
        console.error("Fehler beim Abrufen der Unternehmen:", error);
      }
    };
    fetchCompanies();
  }, []);

  return (
    <div className="home-container">
      <Hero setSearchResults={setSearchResults} />

      {searchResults.length > 0 ? (
        <div className="search-results">
          <h2>Suchergebnisse:</h2>
          <ul>
            {searchResults.map((company) => (
              <li key={company._id}>{company.name}</li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <Categories limit={4} /> {/* Zeigt nur die ersten 4 Kategorien */}
          <TopRated />
          <Map />
          <NewestReviews />
        </>
      )}
    </div>
  );
};

export default Home;
