import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Header from "./components/Header";
import AllCategories from "./pages/AllCategories";
import NewReview from "./pages/CustomerReview";
import CompanyPage from "./pages/CompanyPage";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categories" element={<AllCategories />} />
        <Route path="/newreview" element={<NewReview />} />
        <Route path="/login" element={<Login />} />
        <Route path="/company/:id" element={<CompanyPage />} />
      </Routes>
    </Router>
  );
}


export default App;



