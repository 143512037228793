import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/components/Header.css"; // Styling für den Header

const Header = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Überprüfen, ob der Nutzer eingeloggt ist (Token vorhanden)
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token); // true, wenn Token existiert, sonst false
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token"); // Token löschen
    setIsAuthenticated(false);
    navigate("/"); // Zur Startseite weiterleiten
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">Vivares</Link>
      </div>
      <nav className="menu">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/categories">Kategorien</Link></li>
          <li><Link to="/newreview">Bewertung schreiben</Link></li>
          <li><Link to="/business">Für Unternehmen</Link></li>
          {isAuthenticated ? (
            <li><button className="logout-btn" onClick={handleLogout}>Logout</button></li>
          ) : (
            <li><Link to="/login">Login</Link></li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
