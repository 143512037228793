import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCompanyById, getReviewsByCompany } from "../api";
import "../styles/pages/CompanyPage.css";

const CompanyPage = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyData, reviewData] = await Promise.all([
          getCompanyById(id),
          getReviewsByCompany(id),
        ]);
        setCompany(companyData);
        setReviews(reviewData);
      } catch (err) {
        setError("Daten konnten nicht geladen werden. Versuche es später erneut.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <p>Lädt...</p>;
  if (error) return <p className="error-message">{error}</p>;
  if (!company) return <p>Unternehmen nicht gefunden.</p>;

  return (
    <div className="company-page">
      <div className="company-header">
        <img
          src={company.logo || "/placeholder-logo.png"}
          alt={company.name}
          className="company-logo"
        />
        <h1>{company.name}</h1>
        <p>{company.address || "Keine Adresse angegeben"}</p>
      </div>

      <div className="company-details">
        <h2>Über uns</h2>
        <p>{company.description || "Keine Beschreibung verfügbar."}</p>
        <h3>Öffnungszeiten</h3>
        <p>{company.openingHours || "Keine Öffnungszeiten angegeben."}</p>
      </div>

      <div className="reviews-section">
        <h2>Bewertungen</h2>
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <div key={review._id} className="review-card">
              <p><strong>{review.user?.name || "Anonymer Nutzer"}</strong>: {review.comment || "Kein Kommentar"}</p>
              <p>⭐ {review.rating}/5</p>
            </div>
          ))
        ) : (
          <p>Noch keine Bewertungen vorhanden.</p>
        )}
      </div>
    </div>
  );
};

export default CompanyPage;
