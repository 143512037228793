import API from "./index";

/** Holt eine Liste aller Unternehmen */
export const getAllCompanies = async () => {
  const response = await API.get("/api/companies");
  return response.data;
};

/** Holt ein einzelnes Unternehmen basierend auf der ID */
export const getCompanyById = async (companyId) => {
  const response = await API.get(`/api/companies/${companyId}`);
  return response.data;
};

/** Durchsucht Unternehmen basierend auf einem Suchbegriff */
export const searchCompanies = async (query) => {
  const response = await API.get(`/api/companies/search?query=${query}`);
  return response.data;
};

/** Holt die am besten bewerteten Unternehmen */
export const getTopRatedCompanies = async () => {
  const response = await API.get("/api/companies/top-rated");
  return response.data;
};
