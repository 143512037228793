import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/pages/Login.css"; // Falls nötig, eine CSS-Datei für das Styling

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("Login für Kunden ist noch nicht verfügbar."); // Platzhalter-Nachricht
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled>
          Login (Bald verfügbar)
        </button>
      </form>
    </div>
  );
};

export default Login;
