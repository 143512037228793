import React from "react";
import ReviewForm from "../components/ReviewForm";
import "../styles/pages/CustomerReview.css";

const CustomerReview = () => {
  return (
    <div className="customer-review-page">
      <h1>Bewertung abgeben</h1>
      <p>Teile deine Erfahrung und hilf anderen Kunden, die besten Unternehmen zu finden.</p>
      <ReviewForm />
    </div>
  );
};

export default CustomerReview;
