import React, { useEffect, useState } from "react";
import { getNewestReviews } from "../api/review";
import "../styles/components/NewestReviews.css";

const NewestReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const data = await getNewestReviews();
        setReviews(data);
      } catch (err) {
        setError("Fehler beim Laden der Bewertungen");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) return <p>Lade neueste Bewertungen...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="newest-reviews">
      <h2>Neueste Bewertungen</h2>
      {reviews.length === 0 ? (
        <p className="no-reviews">Es gibt noch keine Bewertungen.</p>
      ) : (
        <div className="reviews-list">
          {reviews.map((review) => (
            <div key={review._id} className="review-card">
              <h3>{review.companyBranch?.name || "Unbekanntes Geschäft"}</h3>
              <p>"{review.comment}"</p>
              <div className="review-meta">
                <span>⭐ {review.rating}</span>
                <span>
                  {review.createdAt
                    ? new Intl.DateTimeFormat("de-DE", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(new Date(review.createdAt))
                    : "Datum nicht verfügbar"}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NewestReviews;
