import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "../styles/components/Map.css";

// API-Schlüssel von Mapbox (🔒 Besser aus einer .env-Datei laden)
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "pk.eyJ1IjoiYWVtbXJhaCIsImEiOiJjbTRlbDlpbjEwc2pxMmpzYXNuMXVseDRpIn0.tE-0ip5k8x3yIshy9xu4FA";

const Map = ({ businesses = [] }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapContainerRef.current || mapRef.current) return; // Verhindert doppelte Initialisierung

    // Karte initialisieren
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [13.405, 52.52], // Standard: Berlin
      zoom: 10,
    });

    mapRef.current = map; // Speichert die Map-Instanz

    // Falls Unternehmen mit Koordinaten übergeben wurden, Marker setzen
    if (businesses.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();

      businesses.forEach((business) => {
        if (business.coordinates) {
          new mapboxgl.Marker()
            .setLngLat(business.coordinates)
            .setPopup(new mapboxgl.Popup().setText(business.name))
            .addTo(map);

          bounds.extend(business.coordinates);
        }
      });

      map.fitBounds(bounds, { padding: 50, maxZoom: 14 });
    }

    // Cleanup
    return () => map.remove();
  }, [businesses]);

  return <div ref={mapContainerRef} className="map-container" />;
};

export default Map;
