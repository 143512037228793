import React, { useState, useEffect } from "react";
import { postReview } from "../api/review";
import { getAllCompanies } from "../api/company";
import "../styles/components/ReviewForm.css";

const ReviewForm = () => {
  const [formData, setFormData] = useState({
    user: "",
    company: "",
    rating: 0,
    comment: "",
  });

  const [companies, setCompanies] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Holt User-ID aus localStorage (falls eingeloggt)
    const userToken = localStorage.getItem("token");
    if (userToken) {
      try {
        const decodedToken = JSON.parse(atob(userToken.split(".")[1]));
        setFormData((prev) => ({ ...prev, user: decodedToken.id }));
      } catch (error) {
        console.error("Fehler beim Token-Dekodieren:", error);
      }
    }

    // Holt alle Unternehmen aus der API
    const fetchCompanies = async () => {
      try {
        const response = await getAllCompanies();
        setCompanies(response);
      } catch (error) {
        console.error("Fehler beim Laden der Unternehmen:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "rating" ? Number(value) : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.user || !formData.company || !formData.rating) {
      setMessage("Bitte füllen Sie alle erforderlichen Felder aus.");
      return;
    }

    try {
      await postReview(formData);
      setMessage("✅ Bewertung erfolgreich gespeichert!");

      // Formular zurücksetzen
      setFormData({
        user: formData.user, // User bleibt gesetzt
        company: "",
        rating: 0,
        comment: "",
      });

      // Erfolgsnachricht nach 3 Sekunden entfernen
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error("Fehler beim Speichern der Bewertung:", error);
      setMessage("❌ Fehler beim Speichern der Bewertung.");
    }
  };

  return (
    <div className="review-form">
      <h2>Bewertung abgeben</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          Unternehmen:
          <select
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
          >
            <option value="">Unternehmen auswählen</option>
            {companies.map((company) => (
              <option key={company._id} value={company._id}>
                {company.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Sternebewertung:
          <input
            type="number"
            name="rating"
            value={formData.rating}
            onChange={handleChange}
            min="1"
            max="5"
            required
          />
        </label>
        <label>
          Kommentar:
          <textarea
            name="comment"
            value={formData.comment}
            onChange={handleChange}
          ></textarea>
        </label>
        <button type="submit">Bewertung speichern</button>
      </form>
    </div>
  );
};

export default ReviewForm;
