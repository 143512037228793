import API from "./index";

/** Holt die Bewertungen für ein bestimmtes Unternehmen */
export const getReviewsByCompany = async (companyId) => {
  const response = await API.get(`/api/reviews/${companyId}`);
  return response.data;
};

/** Erstellt eine neue Bewertung */
export const postReview = async (reviewData) => {
  const response = await API.post("/api/reviews", reviewData);
  return response.data;
};

/** Holt die neuesten Bewertungen */
export const getNewestReviews = async () => {
  const response = await API.get("/api/reviews/newest");
  return response.data;
};
